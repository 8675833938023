var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isInitialized } from '..';
import { isParamsInit } from '../../types.guard';
import iframeCreate from './iframeCreate';
/**
 * @deprecated Since __epilot interface provides much more flexibility. Use initByOptions instead
 */
export var initByParams = function (params, contextData) {
    if (!isParamsInit(params)) {
        console.error('Required Script Source Params do not match definitions. Params passed: ', params);
        return;
    }
    var journeys = [];
    // init only if not already initialized
    var journeyIds = params.journeyIds, otherParams = __rest(params
    // either use passed journeyIds via init() function, or default to the ones passed as data attribute
    , ["journeyIds"]);
    // either use passed journeyIds via init() function, or default to the ones passed as data attribute
    params.journeyIds.forEach(function (journeyId) {
        if (isInitialized(journeyId)) {
            return;
        }
        // create iframe with params
        var journey = iframeCreate(__assign(__assign({ journeyId: journeyId }, otherParams), { contextData: contextData }));
        journeys.push(__assign({ id: journeyId }, journey[journeyId]));
    });
    return journeys;
};
