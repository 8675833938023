var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isInitialized } from '..';
import iframeCreate from './iframeCreate';
export var initByOptions = function (options) {
    var journeys = [];
    options.forEach(function (optionInit) {
        var journeyId = optionInit.journeyId;
        if (isInitialized(journeyId)) {
            return;
        }
        // create iframe with params
        var journey = iframeCreate(optionInit);
        journeys.push(__assign({ id: journeyId }, journey[journeyId]));
    });
    return journeys;
};
