import { JourneyEventType } from '../types';
import { mutateIframeStyling } from '../utils/mutateIframeStyling';
import { journeys } from '.';
export var exitFullScreen = function (journeyId, payload) {
    var _a, _b;
    var iframe = (_a = journeys[journeyId]) === null || _a === void 0 ? void 0 : _a.el;
    if (iframe && iframe.contentWindow) {
        iframe.style.cssText = iframe.dataset.oldStyle || '';
        // return original overflow to iframe's parent body
        window.document.body.style.overflow = ((_b = iframe.dataset) === null || _b === void 0 ? void 0 : _b.bodyOverflow) || 'auto';
        if (payload === null || payload === void 0 ? void 0 : payload.isLauncherJourney) {
            mutateIframeStyling(iframe, { journeyId: journeyId, mode: 'inline' });
        }
        // send completion event back to iframe
        iframe.contentWindow.postMessage({
            type: JourneyEventType.exitFullScreen,
            status: 'success',
            journeyId: journeyId,
            payload: payload
        }, '*');
    }
};
