import { getJourneyById, journeys } from '.';
/**
 * Removes an iframe from the DOM and the journey map
 */
export var remove = function (journeyId) {
    var _a;
    var iframe = (_a = getJourneyById(journeyId)) === null || _a === void 0 ? void 0 : _a.el;
    delete window['__epilotJourneyMap'][journeyId];
    if (iframe) {
        delete journeys[journeyId];
        iframe.remove();
    }
};
