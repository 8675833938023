// the fields are firstName, lastName, email, telephone, birthDate, companyName, registryCourt, registerNumber
export var PersonalInformationField;
(function (PersonalInformationField) {
    PersonalInformationField["FirstName"] = "firstName";
    PersonalInformationField["LastName"] = "lastName";
    PersonalInformationField["Email"] = "email";
    PersonalInformationField["Phone"] = "telephone";
    PersonalInformationField["BirthDate"] = "birthDate";
    PersonalInformationField["CompanyName"] = "companyName";
    PersonalInformationField["RegistryCourt"] = "registryCourt";
    PersonalInformationField["RegisterNumber"] = "registerNumber";
})(PersonalInformationField || (PersonalInformationField = {}));
// fields are countryCode, zipCity, streetName, houseNumber, extention
export var AddressField;
(function (AddressField) {
    AddressField["CountryCode"] = "countryCode";
    AddressField["StreetName"] = "streetName";
    AddressField["HouseNumber"] = "houseNumber";
    AddressField["Extention"] = "extention";
    AddressField["ZipCity"] = "zipCity";
})(AddressField || (AddressField = {}));
// the fields are startDate, endDate
export var DateField;
(function (DateField) {
    DateField["StartDate"] = "startDate";
    DateField["EndDate"] = "endDate";
})(DateField || (DateField = {}));
export var JourneyEventType;
(function (JourneyEventType) {
    JourneyEventType["init"] = "EPILOT/INIT";
    JourneyEventType["updateJourney"] = "EPILOT/UPDATE_JOURNEY";
    JourneyEventType["exitFullScreen"] = "EPILOT/EXIT_FULLSCREEN";
    JourneyEventType["enterFullScreen"] = "EPILOT/ENTER_FULLSCREEN";
    JourneyEventType["closeJourney"] = "EPILOT/CLOSE_JOURNEY";
    JourneyEventType["formChange"] = "EPILOT/FORM_EVENT";
    JourneyEventType["pageView"] = "EPILOT/USER_EVENT/PAGE_VIEW";
    JourneyEventType["journeyIframeReady"] = "EPILOT/JOURNEY_LOADED";
})(JourneyEventType || (JourneyEventType = {}));
