import { isJourneyMode } from '../types.guard';
export var getScriptParams = function () {
    // data-journeyIds is legacy, but needs to be supported
    var script = document.querySelector('[data-ep-journeyIds]') ||
        document.querySelector('[data-journeyIds]');
    if (script) {
        var journeyIdsRaw = (script === null || script === void 0 ? void 0 : script.getAttribute('data-ep-journeyIds')) ||
            (script === null || script === void 0 ? void 0 : script.getAttribute('data-journeyIds'));
        var mode = script === null || script === void 0 ? void 0 : script.getAttribute('data-ep-mode');
        var params = {
            journeyIds: (journeyIdsRaw && journeyIdsRaw.split(',')) || undefined,
            minHeight: (script === null || script === void 0 ? void 0 : script.getAttribute('data-ep-min-height')) || undefined,
            mode: isJourneyMode(mode) ? mode : undefined,
            init: (script === null || script === void 0 ? void 0 : script.getAttribute('data-ep-init')) === 'false' ? false : true
        };
        var url = script.getAttribute('src') || '';
        var queryString = url.substring(url.lastIndexOf('?')); // find query string from url
        var urlSearchParams = new URLSearchParams(queryString);
        var data = Object.fromEntries(urlSearchParams.entries());
        return { params: params, data: data };
    }
    return {};
};
