import { JourneyEventType } from '../types';
/**
 * Dispatches a custom event
 */
export var dispatch = function (eventName, payload) {
    // use JourneyEventType for better isolation
    var event = new CustomEvent(JourneyEventType[eventName], {
        detail: payload
    });
    document.dispatchEvent(event);
};
