import { JourneyEventType } from '../types';
function isCustomEvent(event) {
    return 'detail' in event;
}
/**
 * Setups an event listener and a callback function for the given eventName
 * @param eventName one of 'init' | 'enterFullScreen' | 'exitFullScreen'
 * @param cb callback function to be executed when event is triggered
 */
export var on = function (eventName, cb) {
    // use JourneyEventType for better isolation
    document.addEventListener(JourneyEventType[eventName], function (event) {
        if (!isCustomEvent(event))
            throw new Error('not a custom event');
        cb(event.detail, event);
    });
};
