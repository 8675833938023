var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { addJourneyById, on } from '..';
import { JOURNEY_URL, NODE_ENV } from '../../config';
import { JourneyEventType } from '../../types';
import { getQueryParams } from '../../utils/getQueryParams';
import { mutateIframeStyling } from '../../utils/mutateIframeStyling';
function iframeCreate(params) {
    var _a;
    var _b;
    var journeyId = params.journeyId, lang = params.lang, otherParams = __rest(params
    // add queryParams
    , ["journeyId", "lang"]);
    // add queryParams
    var queryParams = getQueryParams();
    otherParams['queryParams'] = queryParams;
    // get container where iframe will be placed in
    var container = document.getElementById("epilot-journey-" + journeyId);
    var iframe = document.createElement('iframe');
    // journeyUrl can be overridden (alternative hosting for journey app)
    var journeyUrl = (_b = params.journeyUrl) !== null && _b !== void 0 ? _b : JOURNEY_URL;
    iframe.src =
        journeyUrl + "?" +
            __spreadArray(__spreadArray([
                "journeyId=" + journeyId,
                otherParams.mode ? "mode=" + otherParams.mode : undefined,
                typeof otherParams.topBar === 'boolean'
                    ? "topBar=" + otherParams.topBar
                    : undefined,
                lang ? "lang=" + lang : undefined,
                // otherParams.dataInjectionOptions must be encoded as base64
                otherParams.dataInjectionOptions
                    ? "dataInjectionOptions=" + btoa(JSON.stringify(otherParams.dataInjectionOptions))
                    : undefined
            ], Object.entries(queryParams)
                .map(function (_a) {
                var key = _a[0], value = _a[1];
                return (value ? key + "=" + value : undefined);
            })
                .filter(Boolean), true), [
                'isEmbedded=true'
            ], false).filter(Boolean)
                .join('&');
    iframe.style.border = 'none';
    if (NODE_ENV === 'test') {
        iframe.setAttribute('data-testid', journeyId);
    }
    // pass params to iframe once loaded
    if (iframe) {
        on('journeyIframeReady', function (_a) {
            var _b;
            var journeyIdToInit = _a.journeyId;
            if (journeyIdToInit === journeyId) {
                (_b = iframe === null || iframe === void 0 ? void 0 : iframe.contentWindow) === null || _b === void 0 ? void 0 : _b.postMessage({
                    type: JourneyEventType.init,
                    status: 'effect',
                    journeyId: journeyId,
                    payload: __assign(__assign({}, otherParams), { initialMessageEventReceived: true })
                }, '*');
                // Set styling depending on mode
                if (params.mode === 'inline') {
                    mutateIframeStyling(iframe, params);
                }
            }
        });
        if (params.mode !== 'inline') {
            mutateIframeStyling(iframe, params);
        }
        else if (params.mode === 'inline') {
            iframe.style.cssText =
                'display:block; border: none; min-width: 100%; width: 1px';
        }
    }
    // append iframe to HTML
    if (container) {
        container.appendChild(iframe);
        iframe.id = "epilot-journey-embedded-" + journeyId;
    }
    else {
        iframe.id = "epilot-journey-" + journeyId;
        document.body.appendChild(iframe);
    }
    // add journey to map and return created
    var journey = (_a = {},
        _a[journeyId] = { el: iframe, isInitialized: true },
        _a);
    addJourneyById(journeyId, {
        el: iframe,
        isInitialized: true
    });
    return journey;
}
export default iframeCreate;
