import { iframeResizer } from 'iframe-resizer';
import { resizeJourney } from '../api/resizeJourney';
import { isDebugMode } from './isDebugMode';
export var mutateIframeStyling = function (iframe, params) {
    if (params.mode === 'inline') {
        // init iframe resizer if mode is inline
        var height = iframe.style.height;
        iframe.style.cssText = "display:block; border: none; min-width: 100%; width: 1px; " + (height ? "height: " + height : '');
        var minHeight = params.minHeight;
        var options = {
            log: isDebugMode(),
            heightCalculationMethod: 'taggedElement',
            minHeight: (minHeight && parseInt(minHeight)) || 0,
            checkOrigin: false,
            onInit: function () { return resizeJourney(params.journeyId); }
        };
        iframeResizer(options, iframe);
    }
    else if (params.mode === 'full-screen') {
        // hide if mode is fullscreen
        iframe.style.display = 'none';
        // remove listeners if full-screen
        removeIframeResizerListeners(iframe);
    }
    else {
        iframe.width = '100%';
        iframe.height = '100%';
    }
};
export var removeIframeResizerListeners = function (iframe) {
    var _a;
    var resizer = iframe === null || iframe === void 0 ? void 0 : iframe.iFrameResizer;
    if (resizer) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;
        (_a = resizer) === null || _a === void 0 ? void 0 : _a.removeListeners();
    }
};
