var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { JourneyEventType } from '../types';
import { mutateIframeStyling } from '../utils/mutateIframeStyling';
import { getJourneyById } from '.';
export var update = function (journeyId, payload) {
    var _a;
    var iframe = (_a = getJourneyById(journeyId)) === null || _a === void 0 ? void 0 : _a.el;
    if (iframe && iframe.contentWindow) {
        // Set styling depending on mode
        mutateIframeStyling(iframe, __assign({ journeyId: journeyId }, payload));
        // send completion event back to iframe
        iframe.contentWindow.postMessage({
            type: JourneyEventType.updateJourney,
            status: 'effect',
            journeyId: journeyId,
            payload: payload
        }, '*');
    }
};
