// will be made available in __epilot. Check webpack config or https://webpack.js.org/guides/author-libraries/#expose-the-library
export * from './api';
import __epilot from './api';
(function initJourney() {
    // eslint-disable-next-line import/no-named-as-default-member
    var init = __epilot.init;
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', function () { return init([], true); });
    }
    else {
        init([], true);
    }
})();
// add __epilot to window object
// note: not using webpacks output lib as multiple <script> tags would overwrite previous __epilot object
if (!window['__epilotJourneyMap']) {
    window['__epilotJourneyMap'] = {};
}
// add __epilot to __epilotJourney under window as a fallback for customers using widgets + journeys on the same page,
// as both scripts would write to __epilot
if (!window['__epilotFallback']) {
    window['__epilotFallback'] = __epilot;
}
