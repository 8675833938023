// re-export for external usage, i.e. by the consumer of the lib
import { enterFullScreen } from './enterFullScreen';
import { exitFullScreen } from './exitFullScreen';
import { init } from './init';
import { on } from './on';
import { remove } from './remove';
import { resizeJourney } from './resizeJourney';
import { update } from './update';
export { enterFullScreen };
export { exitFullScreen };
export { resizeJourney };
export { update };
export { remove };
export { on };
export { init };
export var journeys = {};
export var isInitialized = function (journeyId) {
    var _a;
    if (window['__epilotJourneyMap']) {
        return window['__epilotJourneyMap'][journeyId]
            ? window['__epilotJourneyMap'][journeyId].isInitialized
            : false;
    }
    return ((_a = journeys[journeyId]) === null || _a === void 0 ? void 0 : _a.isInitialized) || false;
};
export var getJourneyById = function (journeyId) {
    var _a;
    if (window['__epilotJourneyMap']) {
        return (_a = window['__epilotJourneyMap']) === null || _a === void 0 ? void 0 : _a[journeyId];
    }
    return journeys[journeyId];
};
export var addJourneyById = function (journeyId, item) {
    var _a;
    if (!window['__epilotJourneyMap'])
        window['__epilotJourneyMap'] = {};
    if (window['__epilotJourneyMap']) {
        window['__epilotJourneyMap'][journeyId] = item;
    }
    // support legacy
    Object.assign(journeys, (_a = {}, _a[journeyId] = item, _a));
};
// for internal usage
var __epilot = {
    journeys: journeys,
    isInitialized: isInitialized,
    on: on,
    init: init,
    enterFullScreen: enterFullScreen,
    exitFullScreen: exitFullScreen,
    resizeJourney: resizeJourney,
    remove: remove,
    getJourneyById: getJourneyById,
    addJourneyById: addJourneyById
};
export default __epilot;
