import { JourneyEventType } from '../types';
import { removeIframeResizerListeners } from '../utils/mutateIframeStyling';
import { getJourneyById } from '.';
export var enterFullScreen = function (journeyId, payload) {
    var _a;
    var iframe = (_a = getJourneyById(journeyId)) === null || _a === void 0 ? void 0 : _a.el;
    if (iframe) {
        if (!iframe.dataset.oldStyle) {
            iframe.dataset.oldStyle = iframe.style.cssText;
        }
        if (payload === null || payload === void 0 ? void 0 : payload.isLauncherJourney) {
            removeIframeResizerListeners(iframe);
        }
        iframe.style.height = '100%';
        iframe.style.width = '100%';
        iframe.style.position = 'fixed';
        iframe.style.top = '0px';
        iframe.style.left = '0px';
        iframe.style.bottom = '0px';
        iframe.style.right = '0px';
        iframe.style.backgroundColor = '#fff';
        /**
         * Note: Although scrolling is obsolete, when specified it overrides the style.overflow causing a bug.
         */
        iframe.scrolling = 'auto';
        iframe.style.overflow = 'auto';
        iframe.style.display = 'block';
        iframe.style.zIndex = '9999999';
        // prevent scroll in iframe's parent body
        window.document.body.style.overflow = 'hidden';
        if (iframe.contentWindow) {
            // send completion event back to iframe
            iframe.contentWindow.postMessage({
                type: JourneyEventType.enterFullScreen,
                status: 'success',
                journeyId: journeyId,
                payload: payload
            }, '*');
        }
    }
};
