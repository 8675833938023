export function isJourneyMode(obj, _argumentName) {
    return obj === 'inline' || obj === 'full-screen';
}
export function isParamsInit(obj, _argumentName) {
    return (((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
        Array.isArray(obj.journeyIds) &&
        obj.journeyIds.every(function (e) { return typeof e === 'string'; }) &&
        (typeof obj.minHeight === 'undefined' ||
            typeof obj.minHeight === 'string') &&
        (typeof obj.mode === 'undefined' ||
            obj.mode === 'inline' ||
            obj.mode === 'full-screen') &&
        typeof obj.init === 'boolean');
}
