import { JourneyEventType } from '../types';
import { getJourneyById } from '.';
/**
 * Resizes the journey iframe based on the provided journey ID and payload.
 * @param journeyId - The ID of the journey.
 * @param payload - Optional payload containing additional properties to be sent to the iframe.
 */
export var resizeJourney = function (journeyId, payload) {
    var _a;
    var iframe = (_a = getJourneyById(journeyId)) === null || _a === void 0 ? void 0 : _a.el;
    if (iframe) {
        var iFrameResizer_1 = iframe.iFrameResizer;
        if (iFrameResizer_1) {
            // Clear the existing interval if it's active
            if (iframe.resizeInterval) {
                clearInterval(iframe.resizeInterval);
                iframe.resizeInterval = undefined;
            }
            // Set up the new interval
            iframe.resizeInterval = setInterval(function () {
                iFrameResizer_1.resize();
            }, 500);
            // Clear the existing timeout if it's active
            if (iframe.resizeTimeout) {
                clearTimeout(iframe.resizeTimeout);
                iframe.resizeTimeout = undefined;
            }
            // Set up a new timeout to clear the interval after 5 seconds
            iframe.resizeTimeout = setTimeout(function () {
                clearInterval(iframe.resizeInterval);
                iframe.resizeInterval = undefined;
            }, 5000);
            if (iframe.contentWindow) {
                // send completion event back to iframe
                iframe.contentWindow.postMessage({
                    type: JourneyEventType.pageView,
                    status: 'success',
                    journeyId: journeyId,
                    payload: payload
                }, '*');
            }
        }
    }
};
